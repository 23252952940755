.refundReceipt {
  background: #fff;
  padding: 0 16px 64px 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  max-width: 900px;
  min-height: 350px;
  position: relative;

  &Title {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin: 8px 0;
    }
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  &Body {
    padding: 16px 0;


    @media (min-width: 768px) {
      column-count: 2;
      column-fill: auto;
    }
  }

  &Section {
    width: 100%;
    display: block;
    margin-bottom: 8px;

    @media (min-width: 768px) {
      min-width: 350px;
      margin-bottom: 16px;
      display: inline-block;
    }

    &Title {
      margin-bottom: 8px;
      margin-top: 0;
      font-size: 13px;

      @media (min-width: 768px) {
        font-size: 15px;
      }
    }

    &Row {
      display: flex;
      font-size: 12px;
      width: 100%;

      @media (min-width: 768px) {
        display: table-row;
        font-size: 14px;
      }

      &:not(:first-of-type) {
        > span, > strong {
          padding-top: 8px;
          padding-bottom: 4px;
        }
      }

      > span, > strong {
        display: table-cell;
        width: 50%;

        @media (min-width: 768px) {
          width: auto;
        }
      }

      > span {
        padding-right: 24px;

        @media (min-width: 768px) {
          white-space: nowrap;
        }
      }
    }
  }
}

.refundReceiptEmpty {
  composes: refundReceipt;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hint {
  font-size: 12px;
  position: absolute;
  left: 16px;
  right: 16px;
}

.divider {
  &:nth-of-type(odd) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f1f1f1;
  }
}

.dividerMobile {
  composes: divider;

  &:nth-of-type(odd) {
    border: none;
    padding-bottom: 0;
  }
}

.hr {
  border: 1px solid #f1f1f1;
  border-bottom: none;
}

.hrMobile {
  composes: hr;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    display: none;
    margin-bottom: 16px;
  }
}

.refundLabel, .successLabel {
  padding: 3px 6px;
  font-weight: normal;
  border-radius: 2px;
}

.refundLabel {
  color: #68bcde;
  background: rgba(137, 212, 243, 0.2);
}

.successLabel {
  color: #6ec45f;
  background: rgba(159, 218, 147, 0.2);
}