body {
  background-color: #e5e5e5;
}

.wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.root2 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.rightAdorn {
  position: absolute;
  right: 12px;
}

.leftAdorn {
  position: absolute;
  left: 12px;
}

.searchInput {
  width: 100%;
  padding: 3px 32px 3px 32px;
  border: none;
  outline: none;
  height: 34px;

  font-family: 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #000000;
}

.searchInput input::placeholder {
  font-family: 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.24);
}

.root {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.title {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
.flex1 {
  flex-grow: 1;
}
.topText {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.row {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 1px #000000;
  width: 240px;
  height: 64px;
}

.checkContainer {
  width: 100%;
  display: flex;
  padding: 12px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkItem {
  margin-right: 18px;
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxActive {
  background-color: #000000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.searchContainer {
  background-color: #ffffff;
  position: relative;
  width: 100%;
  padding: 0px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  padding: 8px 16px;
  margin-bottom: 175px;
  position: relative;
}
.botText {
  font-family: 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;

  text-align: center;

  color: #000000;
}

.infoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.infoContainer > div {
  width: 100%;
}

.button2 {
  margin-top: 8px;
  padding: 15px 0px;
  flex: 1;
  margin-left: 20px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #a1a1a1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.button2:disabled {
  opacity: 0.5;
  background: grey;
  pointer-events: none;
}

.button2Active {
  background: #00bcd4;
  color: #ffffff;
}

.button2Refund {
  background: #ff785a;
  color: #ffffff;
}

.fullWidthSelect {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
}

.paidSection {
  margin-top: 8px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  text-align: center;

  color: #868686;
}

.paymentBody {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.paymentBottom {
  filter: drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.1));
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 26px 16px 24px 16px;
  z-index: 2;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.543846);
}

.paymentBottomFixed {
  filter: drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.1));
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 26px 16px 24px 16px;
  z-index: 2;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.543846);
}

.bottom2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bottom2 div {
  margin: 15px 0px 15px 0px;
}

.paymentBottom2 {
  /* height: 276px; */
  padding: 16px 16px 24px 16px;
}

.bottomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentTable {
  margin-right: 22px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;

  display: flex;
  align-items: center;
  color: #ffffff;
}

.refundReceiptButton {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: inherit;
}

.refundIcon {
  color: inherit;
  margin-right: 8px;
}

.tableIcon {
  margin-right: 8px;
}

.selHeader {
  background: #000000;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.selTop {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.selTop2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  height: 32px;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;

  color: #ffffff;
}

.selComment {
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;

  color: #000000;
}

.selBody {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 156px);
  background: #ffffff;
  padding: 8px;

  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;

  color: #000000;
}

.selComment {
  background: #eeeeee;
  padding: 8px 8px 11px 8px;
}

.selOrder {
  background: #ffffff;
  padding: 8px 8px 6px 8px;
}

.selOrder:nth-child(odd) {
  background: #eeeeee;
}

.selTotal {
  background: #868686;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;

  color: #ffffff;
}

.setRefund {
  background: #ff785a;
}

.bottomSpacer {
  padding: 20px;
  width: 100%;
  min-height: 100vh;
}

.setTotalPrice {
  font-weight: 600;
  /* identical to box height */

  text-align: right;
}

.selTotalFrame {
  padding: 8px;
  min-width: 90px;
  text-align: right;
  border-radius: 2px;
  border: 1px solid #eeeeee;
}

.selTotalFrameSelected {
  background: #ffffff;
  border: 1px solid #000000;
  font-weight: 600;
  width: 90px;
}

.count {
  display: flex;
  align-items: center;
  min-width: 16px;
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}

.mleft4 {
  margin-left: 4px;
}
.mleft8 {
  margin-left: 8px;
}
.bigcount {
  min-width: 16px;
  color: #ff785a;
  font-weight: bold;
}

.selOrderListWrapper {
  display: flex;
  justify-content: space-between;
}

.selOrderList {
  display: flex;
}

.price {
  font-family: Stratos;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: right;

  color: #000000;

  opacity: 0.5;
}

.name {
  display: flex;
  align-items: center;
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;

  color: #000000;
}

.subcat {
  font-family: Stratos;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #000000;

  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
}

.dialogWrapper {
  background: rgba(0, 0, 0, 0.54);

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 11;

  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  background: #fafafa;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  padding: 16px;
  min-width: 218px;
  max-width: calc(100vw - 48px);
}

.dialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.01em;

  color: #000000;
}

.dialogBody {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.01em;

  color: #000000;
}

.wideRow {
  display: flex;
  align-items: center;
}

.orderBox {
  position: relative;
  /* background: #a9cce3; */
  /* background: #f9d953; */
  /* box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
  min-height: 30px;
  padding: 8px 16px;
  font-size: 16px;
  justify-content: space-between;
}

.recIcon {
  margin-right: 8px;
}

.matButton {
  margin: 0px;
  user-select: none;
  cursor: pointer;
  padding: 10px 0px;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 0.26);
}

.disabledButton {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #868686;
}

.textarea {
  min-height: 220px;
  width: 100%;
}

.buttons {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enabledButton {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #00bcd4;
}
