.paymentContainer {
  min-height: 76px;
}

.paymentContainerWrapper {
  margin: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.extraPadding {
  padding: 8px;
}

.childrenPadding .paymentP1 {
  padding: 8px;
}

.unpaiderHeader {
  border-radius: 4px 4px 0px 0px;
  background-color: #00bcd4;
  color: #ffffff;
}

.unpaiderHeader .paymentText1 {
  color: #ffffff;
}

.unpaiderHeader .stroking {
  stroke: #ffffff;
}

.stroking {
  stroke: #000000;
}

.filling {
  fill: #000000;
}

.unpaidContainer {
  background-color: #ffbd5a;
}

.unpaidContainer svg {
  /* stroke: #ffffff; */
}

.unpaidContainer .stroking {
  stroke: #ffffff;
}

.unpaidContainer .filling {
  fill: #ffffff;
}

.paymentP1 {
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logPaymentInactive {
  background: #212121;
}

.logPaymentStarted {
  background: #4f4f4f;
  border-radius: 4px;
  border: 1px solid #797979;
}

.logPaymentError {
  background: #4f4f4f;
  border-radius: 4px;
  border: 1px solid #ff785a;
}

.logPaymentError .logPaymentText {
  color: #ff785a;
}

.logPaymentInactive .paymentText1,
.logPaymentStarted .paymentText1,
.logPaymentError .paymentText1 {
  color: #868686;
}

.logPaymentInactive .paymentText2,
.logPaymentStarted .paymentText2,
.logPaymentError .paymentText2 {
  color: white;
}

.logPaymentInactive .stroking,
.logPaymentStarted .stroking,
.logPaymentError .stroking {
  stroke: #868686;
}

.logPaymentInactive .filling,
.logPaymentStarted .filling,
.logPaymentError .filling {
  fill: #868686;
}

.refundBadge {
  background: #868686;
  border-radius: 4px 4px 0 0;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  position: relative;
}

.paymentP2 {
  display: flex;
  align-items: center;
}

.mleft4 {
  margin-left: 4px;
}
.mleft8 {
  margin-left: 8px;
}
.mbot8 {
  margin-bottom: 8px;
}

.paymentP3 {
  flex: 1;
  text-align: right;
}

.paymentText1 {
  font-family: 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: rgba(0, 0, 0, 0.543846);
}

.paymentText2 {
  font-family: 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: rgba(0, 0, 0, 0.87);
}

.paymentWrap {
  display: flex;
  align-items: center;
}

.unpaidContainer .paymentText1 {
  color: #ffffff;
}
.unpaidContainer .paymentText2 {
  color: rgba(255, 255, 255, 0.79);
}

.blackContainer {
}
