body {
  background-color: #e5e5e5;
}

.red {
  color: white;
  background-color: red;
}
.black {
  color: white;
  background-color: #000000;
}
.orange {
  color: #000;
  background-color: #fff;
}
.white {
  color: white;
  background-color: #000000;
}
.form2 {
  margin: 0px 4px;
  padding: 0px 7px;
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 22px;
}

/*  */

.card {
  margin-top: 40px;
  padding: 0px 8px;
  max-width: 345px;
  width: 100%;
  margin: 1px 8px;
  height: 56px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #ffffff;
  border-radius: 8px;
  color: black;
  display: flex;
  justify-content: center;
}
.stoplisted {
  color: #ffffff;
  background-color: #000000;
}
.card1 {
  font-size: 16px;
  font-weight: bold;
  font-family: Stratos;
  margin: 10px 0px;
  padding: 10px;
  width: 100%;
  height: 56px;
  text-decoration: none;
  background-color: transparent;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inactiveList {
  background-color: white;
  text-decoration: none;
  color: black;
}
.activeList {
  background-color: black;
  color: white;
}
.toolbar {
  padding: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  color: black;
}
.subToolbar {
  position: relative;
  padding: 8px 0px;
  height: 40px;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
}
.slonly {
  position: absolute;
  right: 8px;
  top: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 10px;
  align-items: center;
}
.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 8px 0;
  border-radius: 4px;
}
.table {
  min-width: 500px;
}
.tableWrapper {
  overflow-x: auto;
}
.button {
  margin: 2px;
}
.buttonAdd {
  margin: 30px;
  display: block;
  text-decoration: none;
}
.title {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.flex1 {
  flex-grow: 1;
}
.downBoxShadow {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
}
.appBar {
  transition: margin width ease 0.5s;
}
.appBarShift {
  width: calc(100% - 240px);
  margin-left: 240px;
  transition: margin width ease 0.5s;
}
.hide {
  display: none;
}
.drawerWrapper {
  top: 52px;
}
.drawer {
  width: 240px;
  flex-shrink: 0;
}
.drawerHeader {
  display: flex;
  align-items: center;
  padding: 0px 2px;
  justify-content: flex-end;
}
.content {
  flex-grow: 1;
  padding: 6px;
  transition: margin ease 0.5s;
  margin-left: -240px;
}
.contentShift {
  transition: margin ease 0.5s;
  margin-left: 0px;
}
.topText {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.bottomText {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #868686;
}
.form {
  margin: auto 0;
  padding: 0px 5px;
  font-size: 12px;
}
.disabledVenue {
  padding: 4px;
  background: #ff0015;
  display: flex;
  max-width: 84px;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

/*  */

.inactiveList {
  background-color: white;
  text-decoration: none;
  color: black;
}
.activeList {
  background-color: black;
  color: white;
}
.MuiAppBar-colorPrimary {
  background-color: #f8f8f8;
}
.toolbar {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  padding: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  color: black;
}
.subToolbar {
  color: black;
  height: 40px;
  display: flex;
  padding: 8px 0px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  justify-content: center;
  background-color: #f8f8f8;
}
.table {
  min-width: 500;
}
.tableWrapper {
  overflow-x: auto;
}
.button {
  margin: 2px;
}
.buttonAdd {
  margin: 30px;
  display: block;
  text-decoration: none;
}
.title {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.flex1 {
  flex-grow: 1;
}
.downBoxShadow {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
}
.appBar {
  transition: width ease 0.5s;
}
.appBarShift {
  width: calc(100% - 240px);
  margin-left: 240px;
  transition: width ease 0.5s;
}
.hide {
  display: none;
}
.drawer {
  width: 240px;
  flex-shrink: 0;
}
.drawerPaper {
  width: 240px;
  display: block;
}
.drawerHeader {
  display: flex;
  align-items: center;
  padding: 0px 2px;
  justify-content: flex-end;
}
.content {
  flex-grow: 1;
  padding: 6px;
  transition: margin ease 0.5s;
}
.contentShift {
  transition: margin ease 0.5s;
  margin-left: 0;
}
.topText {
  font-family: Roboto, 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.bottomText {
  font-family: Roboto, 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #868686;
}
.form {
  padding: 5px;
  font-size: 12px;
}
.disabledVenue {
  padding: 4px;
  background: #ff0015;
  display: flex;
  max-width: 84px;
  justify-content: center;
  align-items: center;
  font-family: Roboto, 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

.venueFont {
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.215em;
  text-transform: uppercase;

  color: #000000;
}

.smallVenueFont {
  font-family: Stratos;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.root2 {
  display: flex;
}

.switch {
  margin-left: 8px;
}

.switchBase {
  color: #ff000d;
}
/* '&$checked' {
  color: #00bcd4;
  '& + $track' {
    opacity: 1;
    background-color: rgba(0, 188, 212, 0.26);
    bordercolor: #00bcd4;
  }
}
.focusVisible .thumb {
  color: #00bcd4;
  border: 6px solid #fff;
} */
.thumb {
}
.track {
  border: 1px solid rgba(255, 0, 0, 0.26);
  background-color: rgba(255, 0, 0, 0.26);
}
.checked {
}

.venueInfo {
  min-height: 100px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.venueFlex {
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.vkl {
  color: #000000;
}

.otkl {
  color: #ff0015;
}

.vklDescr2 {
  margin: 12px 12px 8px 12px;
  font-family: -apple-system, -android-system, Roboto, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-align: center;

  color: #868686;
}

.vklDescr {
  min-height: 24px;
  font-family: -apple-system, -android-system, Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.venueButton {
  cursor: pointer;
  padding: 8px;
  margin: 24px 0px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.venueSelector {
  margin: 8px;
  display: block;
  padding: 8px 4px;
  border: none;
  width: calc(100% - 16px);
  background: none;
  border-bottom: 1px solid #000;
}

.logoutContainer {
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
}

.languageSelectWrapper {
  margin-top: 20px;
}

.languageSelectWrapper select {
  font-size: 16px;
  font-weight: 300;
  padding: 8px;
  border-radius: 4px;
  text-transform: uppercase;
}
