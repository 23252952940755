body {
  background-color: '#E5E5E5';
}

.title {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #000000;
}
.flex1 {
  flex-grow: 1;
}
.topText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.root {
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.row {
  display: flex;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.1px;

  /* Black / High Emphasis */

  color: rgba(0, 0, 0, 0.87);

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
