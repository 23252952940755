.overlay {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.root {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
  max-height: 50vh;
  padding: 16px;
  z-index: 999;
}
.toptext {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #888888;
}
.bottomText {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header {
  display: flex;
  flex-direction: column;
}
.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}
