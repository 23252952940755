iframe {
  display: none;
}
@font-face {
  font-family: 'Chalkboard SE';
  src: url('/fonts/Chalkee.ttf') format('ttf'), url('/fonts/Chalkee.otf') format('otf'),
    url('/fonts/Chalkee.dfont') format('dfont');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Stratos';
  src: url('/fonts/Stratos-Regular.woff2') format('woff2'), url('/fonts/Stratos-Regular.woff') format('woff'),
    url('/fonts/Stratos-Regular.svg#Stratos-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Stratos';
  src: url('/fonts/Stratos-SemiBold.woff2') format('woff2'), url('/fonts/Stratos-SemiBold.woff') format('woff'),
    url('/fonts/Stratos-SemiBold.svg#Stratos-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Stratos';
  src: url('/fonts/Stratos-Light.woff2') format('woff2'), url('/fonts/Stratos-Light.woff') format('woff'),
    url('/fonts/Stratos-Light.svg#Stratos-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('/fonts/SFProDisplay-Regular.woff2') format('woff2'), url('/fonts/SFProDisplay-Regular.woff') format('woff'),
    url('/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('/fonts/SFProDisplay-Semibold.woff') format('woff'),
    url('/fonts/SFProDisplay-Semibold.svg#SFProDisplay-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('/fonts/SFProDisplay-Light.woff2') format('woff2'), url('/fonts/SFProDisplay-Light.woff') format('woff'),
    url('/fonts/SFProDisplay-Light.svg#SFProDisplay-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  touch-action: manipulation;
}
