.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.4);
  z-index: 99;
}

.loading {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  border: 3px solid rgba(255,255,255,1);
  border-radius: 50%;
  border-top-color: #FFCF37;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}