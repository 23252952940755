.form {
  width: 100%;
  margin-top: 2px;
}
.submit {
  margin: 6px 0px 4px;
}

.wrapper {
  min-height: 100vh;
  font-size: 24px;
  background-color: #323232;
  display: flex;
  flex-direction: column;
}

.avatar {
  background-color: transparent;
  color: #1e88e5;
  display: flex;
}

.paymentHeader {
  position: relative;

  background-color: #ffffff;
  padding: 0px 0px 16px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  /* identical to box height, or 133% */
  letter-spacing: -0.05em;

  color: #000000;
}

.paymentHeader2 {
  position: relative;

  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  /* identical to box height, or 133% */
  letter-spacing: -0.05em;

  color: #000000;
}

.statusbar {
  max-width: 100%;
  padding: 4px 16px;

  background-color: #262c30;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.5);
  color: #868686;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.statusError {
  background-color: #ff785a;
  color: #ffffff;
}

.main {
  flex: 7;
  padding: 16px;
}

.title {
  margin: 20px 0 20px;
  padding-left: 20px;
}

.no_payment {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
}

.payment__tittle {
  margin: 6px;
}

.paymentBox {
  background: #ffffff;
  margin-bottom: 24px;
  border-radius: 8px;
  position: relative;
  opacity: 1;
}

.activatedStatusFade {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  border-radius: 6px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.6);
}

.confirm {
  position: absolute;
  width: 70%;
  height: 100%;
  text-align: center;
  background-color: rgba(15, 170, 15, 0.7);
  color: #fff;
  text-transform: uppercase;
  font-size: 1.8em;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
}

.confirm > span:nth-child(2) {
  display: block;
  text-transform: none;
  font-size: 80px;
}

.reject {
  position: absolute;
  width: 30%;
  height: 100%;
  text-align: center;
  background-color: rgba(206, 46, 6, 0.7);
  color: #fff;
  text-transform: uppercase;
  font-size: 1.8em;
  top: 0;
  right: 0;
  z-index: 4;
  display: flex;
}

.reject span {
  transform: rotateZ(450deg);
  margin: auto;
}

.confirm span {
  margin: auto;
}

.active {
  border-color: #eb984e;
}

.ordersContainer {
  background: #fafafa;
}

.orderBox {
  position: relative;
  /* background: #a9cce3; */
  /* background: #f9d953; */
  /* box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
  min-height: 30px;
  padding: 8px 16px;
  justify-content: space-between;
}
.tobeeven:nth-child(odd) {
  background-color: #eeeeee;
}

.stopBox {
  background-color: rgba(0, 188, 212, 0.49);
}

.stopBox .orderBox {
  background-color: rgba(0, 188, 212, 0.49);
}

.name {
  display: flex;
  align-items: center;
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;

  color: #000000;
}

.price {
  font-family: Stratos;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: right;

  color: #000000;

  opacity: 0.5;
}

/* logger */

.logicon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logHeader {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #000000;
  border: 1px solid #000000;
  box-sizing: border-box;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */

  color: #ffffff;
}

.logWrapper {
  background: #494949;
  /* flex: 3; */
  width: 264px;
  position: relative;
  overflow: hidden;
}

.mobileHistory {
  width: 100%;
}

.logPaymentWrap {
  position: relative;
  max-height: calc(100vh - 84px);
  overflow-y: scroll;
  top: 0px;
  right: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.logPaymentWrap > div {
  width: 100%;
}

.messageBody {
  display: flex;
  flex-direction: column;
  min-height: 229px;
}

.topInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
}

.messageContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bottomButtons {
  min-width: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logPayment {
  border: 1px solid #797979;
  margin: 4px 8px;
  background: #4f4f4f;
  border-radius: 4px;
  color: #fff;
  padding: 8px;
}

.intErr {
  display: flex;
  align-items: center;
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;

  color: #ffffff;
  margin-right: 16px;
}

.intErrText {
  margin-left: 16px;
}

.payment_id {
  margin: 10px 0;
}

.logPaymentTime {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-transform: capitalize;
}

.logPayment > .logPaymentTime,
.logPayment > .logPaymentFlex2 > .logPaymentFlex .logPaymentTime {
  color: #b0b0b0;
}

.logPaymentFlex {
  display: flex;
  align-items: center;
}

.logPaymentFlex2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logPaymentText {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  color: #ffffff;
}

.logPaymentInactive > .logPaymentTime,
.logPaymentInactive > .logPaymentFlex2 > .logPaymentFlex .logPaymentTime {
  color: #868686;
}

.btnBack {
  color: black;
  background-color: #fff;
  padding: 16px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.65);
}

.paymentCard_shadow.paymentCard_shadow {
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.paymentCard.paymentCard {
  position: fixed;
  top: 50px;
  left: 5%;
  right: 5%;
  bottom: 20px;
  z-index: 10;
  background: #fafafa;
  box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 8px;
  overflow-y: scroll;
}

.paymentBottom {
  display: flex;
  align-items: center;

  background: #fafafa;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
}

.paymentButton {
  border-radius: 4px;

  /* Inside Auto Layout */

  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
  margin: 0px 16px;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.085em;
  text-transform: uppercase;
  min-height: 56px;

  color: #ffffff;
}

.paymntWrap {
  padding: 24px;
}

.paymntHWrap {
  display: flex;
}

.paymntFlx1 {
  flex: 1;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
}

.paymntHr {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.paymntBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  height: 24px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #000000;

  background: #eeeeee;
}

.redButton {
  background: #ff785a;
}

.greenButton {
  background: #7ad089;
}

.outlineButton {
  background: #ffffff;
  border: 2px solid #000000;
  color: #000000;
}

.timer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  /* font-size: 1.3em; */
}

.timerDiv {
  padding: 0 3px;
  /* background: #16a085; */
  /* color: black; */
  /* border-radius: 2px; */
}

.takeout {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.table {
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

.hr3 {
  margin-top: 8px;
  width: 100%;
  height: 4px;
  background-color: black;
}

.hr2 {
  margin-top: 8px;
  width: 100%;
  height: 2px;
  background-color: white;
}

.vr1 {
  margin-left: 16px;
  margin-right: 16px;
  background-color: black;
  min-width: 1px;
  width: 1px;
  border: 1px solid black;
}

.tableFont {
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 24px;
  margin-top: 6px;
}

.flex1 {
  flex: 1;
}

.flex {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.fCenter {
  justify-content: center;
  align-items: center;
}

.fColumn {
  flex-direction: column;
}

.aic {
  align-items: center;
}

.jcBetween {
  justify-content: space-between;
}

.mbottom16 {
  margin-bottom: 16px;
}

.mbottom32 {
  margin-bottom: 32px;
}

.mtop0 {
  margin-top: 0px;
}

.mtop16 {
  margin-top: 16px;
}

.mbottom8 {
  margin-bottom: 8px;
}

.mbottom16 {
  margin-bottom: 16px;
}

.mtop8 {
  margin-top: 8px;
}

.mleft2 {
  margin-left: 2px;
}

.mleft4 {
  margin-left: 4px;
}

.mleft8 {
  margin-left: 8px;
}

.mleft16 {
  margin-left: 16px;
}

.mleft20 {
  margin-left: 20px;
}

.mleft48 {
  margin-left: 48px;
}

.mright4 {
  margin-right: 4px;
}

.mright8 {
  margin-right: 8px;
}

.mright16 {
  margin-right: 16px;
}

.mright20 {
  margin-right: 20px;
}

.mright48 {
  margin-right: 48px;
}

.takeout span > span {
  text-transform: uppercase;
  /* font-size: 0.9em; */
  font-weight: bold;
  display: block;
  color: #6fe465;
}

.total {
  min-height: 48px;
  font-family: Stratos;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: right;

  padding: 0px 16px;

  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: black;
}

.totalNe {
  background-color: #ff785a;
}

.totalLabel {
  opacity: 0.5;
}

.timer_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.paid_time {
  color: #555;
}

.comment {
  padding: 8px 0px;
  margin: 8px 0 0px 0px;
}

.comment_no {
  margin: 7px 0;
  color: #ccc;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.menuNavbarInput {
  background: #323232;
  border: none;
  outline: none;

  font-family: Stratos;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;

  color: white;
}

.menuSearch {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 48px;
  background-color: #323232;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 16px;
  margin-bottom: 16px;
}

.button {
  user-select: none;
  background-color: #2aca1d;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); */

  color: white;
  width: 100%;
  min-height: 96px;

  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
}

.timedIcon {
  fill: #8d8d8d;
  stroke: #8d8d8d;
}

.timedTakeoutButton {
  user-select: none;
  background-color: #00bcd4;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); */
  color: white;
  width: 100%;
  min-height: 96px;
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
}

.closePaymentButton {
  composes: timedTakeoutButton;
  flex: 1 1;
}

.blackButton {
  user-select: none;
  background-color: #000000;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); */

  color: white;
  width: 100%;
  min-height: 96px;

  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.whiteButton {
  user-select: none;
  background-color: #ffffff;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); */

  color: #000000;
  width: 100%;
  margin: 24px 0px;

  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.refundButton {
  user-select: none;
  background: #ff785a;

  color: white;
  width: 100%;
  min-height: 96px;

  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.butOn {
  margin: 12px 10px;
  user-select: none;
  min-width: 96px;

  font-family: Stratos;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #00bcd4;
}

.butOnActive {
  color: #00bcd4;
}

.msgOrder {
  z-index: 2;
  position: fixed;
  top: 32px;
  left: 32px;
  width: 429px;
  min-height: 273px;

  background: #fafafa;
  box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
}

.notButton {
  min-height: 30px;
}

.paymentBoxnth-child .button {
  background-color: #2aca1d;
}

.msgOrder .badge {
  border-radius: 0;
  margin: 0;
}

.badge {
  /* font-size: 16px; */
  font-weight: bold;
  color: white;
  /* padding: 0px 8px; */
  min-height: 32px;
  border-radius: 8px 8px 0px 0px;

  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* margin-bottom: 4px; */
}

.badgeNew {
  background-color: #000000;
}

.badgeWarn {
  background-color: #000000;
}

.badgeDanger {
  background-color: #000000;
}

.paymentBoxWrapper {
  padding: 16px;
  display: flex;
}

.paymentBoxPart {
  display: flex;
  flex-direction: column;
}

.paymentBoxPartScroll {
  display: flex;
  flex-direction: column;
}

.paymentBoxPart1 {
  /* flex: 3; */
  width: 124px;
}

.paymentBoxPart2 {
  background-color: #fafafa;
  flex: 18;
}

.paymentNum {
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 37px;
  /* identical to box height */

  text-align: right;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.215em;
  text-transform: uppercase;

  color: #ffffff;
  position: relative;

  min-height: 60px;
  background-color: black;
}

.subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.215em;
  text-transform: uppercase;

  color: #ffffff;
  position: relative;

  min-height: 48px;
  background-color: black;
}

.roleButton {
  background: #000000;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: normal;
  /* or 114% */

  min-width: 120px;
  padding: 0px 0px 0px 24px;
  min-height: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.5);
}

.lockIcon {
  margin-left: 8px;
  padding: 4px 12px;
  background: rgba(50, 50, 50, 0.54);
  border-radius: 50%;

  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.activeRole .lockIcon {
  color: #00e2ff;
}

.disRole {
  opacity: 0.45;
}

.activeRole {
  box-shadow: inset 0px -2px 0px #00e2ff;
  color: #00e2ff;
}

.roleGroup {
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconHeader {
  display: flex;
  margin-left: 32px;
  margin-right: 98px;
}

.iconHeader2 {
  margin-left: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuList {
  padding: 0;
}

.moblist {
  display: none !important;
}

.absSearch {
  margin: 0px 11px;
}

.inputSearch {
  width: 120px;
  max-width: 120px;

  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;

  outline: none;
  border: none;
}

.iconHeader4 {
  margin-left: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.iconHeader3 {
  margin-right: 16px;
}

.history {
  background: #000000;
  border-radius: 8px 0px 0px 8px;
  width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 84px);
  margin-top: 24px;
  /* transition: width 0.5s ease-out; */
}

.historyText {
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: #ffffff;

  transform: rotate(-90deg);
}

.titleH2 {
  display: flex;
  align-items: center;
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #b3b2b2;
  width: 100%;
  margin: 0;
  padding: 8px;
}

.statusRow {
  display: flex;
  align-items: center;
}

.statusbarItem {
  background: #000000;
  border-radius: 2px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
  padding: 1px 4px;
}

.statusbarItemWrapper {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.animrotate path:nth-child(1) {
  transform-origin: 8px;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.animrotate path:nth-child(2) {
  transform-origin: 8px;
  -webkit-animation: spin 2s reverse infinite;
  -moz-animation: spin 2s reverse infinite;
  animation: spin 2s reverse infinite;
}

.tableWhite {
  color: white;
}

.tableFont1_a {
  font-size: 18px;
  line-height: 18px;
}

.tableFont1 {
  font-size: 16px;
  line-height: 16px;
}

.paymentNum_1 {
  font-size: 24px;
  line-height: 32px;
}

.timerBadge {
  margin-left: 16px;
  font-family: Stratos;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  text-align: right;
  text-transform: capitalize;

  color: #ffffff;
}

.leftFlex {
  display: flex;
  align-items: center;
}

.selsel {
  display: flex;
  align-items: center;
}

.selOpt {
  border-bottom: 1px solid rgba(0, 188, 212, 0.49);
}

.setSel {
  margin-left: 16px;
}

.timerDiv {
  font-weight: 600;
  padding: 0 3px;
  /* background: #16a085; */
  /* color: black; */
  /* border-radius: 2px; */
}

.timeStamp {
  font-family: Stratos;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;

  color: #8d8d8d;
}

.subcat {
  font-family: Stratos;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #000000;

  opacity: 0.5;
  margin-left: 54px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.takeOver {
  z-index: 4;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.45);
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.flexT {
  display: flex;
  align-items: center;
}

.discard {
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: black;
}

.commentText {
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.commentHeader {
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  color: #000000;

  opacity: 0.5;
}

.count {
  min-width: 16px;
}
.bigcount {
  min-width: 16px;
  color: #ff785a;
  font-weight: bold;
}

.tableFontTogo {
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  margin-top: 6px;
  font-size: 16px;
  line-height: 16px;
}

.tableFontTogo1 {
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
}

.takeoutNumTogo {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
}

.contect {
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.inError {
  color: #ff785a;
}

.contectContent {
  padding: 24px;
  background: #fafafa;
  box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  max-width: 760px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.contectCol {
  margin-left: 16px;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.01em;

  color: #000000;
  display: flex;
  flex-direction: column;
}

.contectClose {
  background-color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  color: #00bcd4;
}

.contectBigtext {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #000000;
}

.stopModal {
  z-index: 2;
  position: absolute;
  right: 0px;
  top: 16px;

  padding: 0px 8px;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 2px 8px rgba(0, 0, 0, 0.12);
  width: 221px;

  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 48px;
  /* or 300% */

  color: #cdcdcd;
}

.stopBadge {
  width: 114px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 8px;
  background: #ff785a;
  border-radius: 8px;
  font-family: Stratos;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #ffffff;
}

.headerSection {
  margin-right: 16px;

  font-family: Stratos;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.historyButton {
  width: 96px;
  height: 36px;
  color: #868686;
  /* background: #00BCD4; */
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.activeHistory {
  border-radius: 6px;
  color: #ffffff;
  background: #00bcd4;
}

.headerHr {
  min-width: 1px;
  width: 1px;
  height: 32px;
  background: #515151;
  margin-left: 16px;
}

.refundTimer {
  background: #ff785a;
}

.refundTimer .paiderFont {
  color: #ffffff;
}

.popup {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupContent {
  background-color: #ffffff;
  padding: 24px;
  min-height: 60vh;
  min-width: 50vw;
}

.docPopup {
  position: fixed;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 6, 0.88);
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 10;
}

.docPopupNav {
  position: absolute;
  display: flex;
  bottom: 32px;
  right: 32px;
}

.docPopupNavGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 88px;
  border: 2px solid #00e2ff;
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  margin-right: 16px;
}

.docNav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 93px;
  width: 130px;
  height: 48px;
  border: 2px solid #00e2ff;
}

.docNav.active {
  border: none;
  background-color: #00e2ff;
}

.docNav {
  margin-top: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00e2ff;
}

.docNav.active {
  color: #000000;
}

.docNavWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 192px;
}

.docNavWrap4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 16px;
}

.docNavWrap4 div {
  margin-top: 16px;
}

.docText {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;

  color: #ffce00;
}

.docText li {
  list-style-type: none;
  margin-bottom: 16px;
  max-width: 810px;
}

.docContent {
  display: flex;
  flex-direction: column;
}

.docMain {
  display: flex;
  flex-direction: column;
}

.doc {
  user-select: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
}

.docHr {
  margin: 8px;
  width: 100%;
  height: 1px;
  background-color: #757575;
}

.docMainContent {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  position: relative;
}

.docMainContentAbs {
  position: absolute;
  top: -5px;
  right: 34px;
}

.matButton {
  user-select: none;
  cursor: pointer;
  padding: 10px 0px;
  background-color: white;
}

.disabledButton {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 0.26);
}

.enabledButton {
  z-index: 9;
  padding: 12px;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.newDocText {
  font-family: 'Chalkboard SE';
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;

  color: #ffce00;
}

.newDocHeader {
  font-size: 36px;
  line-height: 51px;
}

.newDocWhite {
  font-family: 'Chalkboard SE';
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;

  color: #ffffff;
}

.docBlocks {
  display: flex;
  flex-direction: column;
}

.docBlocks div {
  margin-top: 16px;
}

.docQR {
  width: 283px;
  height: 283px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  border-radius: 3px;
}

.paiderFont {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: capitalize;

  color: #00bcd4;
}

.timerTab {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  margin-right: 12px;
}

.unpaiderTimer {
  background: #2aca1d;
}

.paiderTimedTimer {
  background: #00bcd4;
}

.unpaiderTimed {
  background-color: #2aca1d;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0px 0px 0px;
}

.paiderTimedTimer .paiderFont {
  color: #ffffff;
}

.unpaiderTimer .paiderFont {
  color: #ffffff;
}

.unpaiderFlex {
  margin: 4px 10px;
  background: rgba(6, 6, 6, 0.2);
  background-blend-mode: multiply;
  border-radius: 8px;
  padding: 0px 4px;

  display: flex;
  align-items: center;

  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #ffffff;
}

.timerSuper {
  display: flex;
  font-family: Stratos;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  color: #ffffff;
}

.unpaiderPosition {
  position: absolute;
  right: 12px;
}

.unpaiderTotal {
  min-height: 48px;
  font-family: Stratos;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: right;

  padding: 0px 16px;

  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #2aca1d;
}

.unpaiderTotal .totalLabel {
  opacity: 1;
}

.snackOffline {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ff3355;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9;

  color: #ffffff;

  font-size: 36px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tableIcon {
  width: 48px;
  height: 35px;
}

.togoIcon {
  width: 47px;
  height: 31px;
}

.typeIcons {
  display: flex;
  justify-content: space-between;
}

.typeIcons:first-of-type {
  margin-top: 8px;
}

.typeIcons:last-of-type {
  margin-bottom: 16px;
}

.mobSide {
  display: none;
}

.togoRender {
  display: flex;
}

.tableRender {
  display: flex;
}

.noDescSide {
  display: none;
}

.root {
  align-items: center;
  border-radius: 2;
  display: inline-flex;
  flex-grow: 0;
  white-space: nowrap;
  cursor: default;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: medium;
  height: 20;
  justify-content: center;
  letter-spacing: 0.5;
  min-width: 20;
  padding: 2px 4px;
  text-transform: uppercase;
}
.primary {
  color: #2196f3;
  background-color: #e8f4fd;
}
.secondary {
  color: #e33371;
  background-color: #fceaf0;
}
.error {
  color: #e57373;
  background-color: #fcf1f1;
}
.success {
  color: #4caf50;
  background-color: #edf7ed;
}
.warning {
  color: #ff9800;
  background-color: #fff4e5;
}

@media (max-width: 525px) {
  .noDescSide {
    display: flex;
  }
  .descSide {
    display: none;
  }
  .mobSideFlex {
    flex: 1;
    display: flex;
    justify-content: center;
    /* display: none; */
    flex-direction: row;
  }
  .typeIcons:first-of-type {
    margin-top: 0px;
  }
  .typeIcons:last-of-type {
    margin-bottom: 0px;
  }
  .typeIcons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tableIcon {
    width: 26px;
    height: 19px;
  }

  .togoIcon {
    width: 29px;
    height: 19px;
  }
  .descFlex {
    display: none;
  }
  .tableRender {
    margin-right: 16px;
    font-size: 24px;
    align-items: center;
    justify-content: center;
  }
  .togoRender {
    font-size: 24px;
    align-items: center;
    justify-content: center;
  }
  .iconHeader {
    display: none;
  }
  .iconHeader2 {
    display: none;
  }
  .mobheader {
    display: flex;
  }
  .moblist {
    display: flex !important;
  }
  .iconHeader3 {
    display: none;
  }
  .iconExit {
    display: flex;
  }
  .header {
    justify-content: center;
    font-size: 20px;
  }
  .paymentBoxWrapper {
    padding: 0px;
    display: flex;
    flex-direction: column-reverse;
  }
  .paymentBoxPart1 {
    margin-top: 8px;
    min-height: 48px;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;
    flex-direction: row;
  }
  .timer {
    position: relative;
    justify-content: unset;
    flex-wrap: wrap;
  }
  .timerBadge {
    align-items: center;
    display: flex;
    min-height: 32px;
    font-size: 15px;
    /* display: none; */
  }
  .vr1 {
    display: none;
  }
  .unpaiderFlex {
    width: 100%;
    font-size: 17px;
    flex-direction: column;
  }
  .timerTab {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .hr3 {
    display: none;
  }
  .name {
    font-size: 17px;
  }
  .subcat {
    font-weight: 500;
    font-size: 12px;
  }
  .paiderFont {
    font-size: 14px;
  }
  .timerSuper {
    font-size: 15px;
    align-items: center;
    min-height: 32px;
  }
  .orderBox {
    padding: 0px 8px 6px 8px;
  }
  .unpaiderTotal {
    font-size: 18px;
  }
  .price {
    font-weight: normal;
  }
  .totalLabel {
    font-size: 18px;
  }
  .paymentNum {
    font-size: 18px;
  }
  .tableFont {
    font-size: 18px;
  }
  .button {
    min-height: 48px;
    flex: 3;
    border-radius: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .whiteButton {
    min-height: 48px;
    flex: 3;
    border-radius: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .timedTakeoutButton {
    min-height: 48px;
    flex: 3;
    border-radius: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .buttons {
    /* right: inherit;
    left: 16px; */
    position: relative;
    justify-content: flex-start;
  }
}

.moreIconWrapper {
  position: relative;
}

.morePopupWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  color: #000;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 0px 4px;
  transform: translateY(calc(100% + 14px)) translateX(16px);
  z-index: 100;
}

.morePopupWrapper div {
  width: max-content;
}

.morePopupWrapperActive {
  opacity: 1;
  visibility: visible;
}

.venueSelector {
  font-size: 14px;
  margin: 8px 0;
  padding: 4px;
  width: 100%;
}

.languageSelectWrapper select {
  font-size: 16px;
  font-weight: 300;
  padding: 8px;
  border-radius: 4px;
  text-transform: uppercase;
}

.clock {
  font-size: 14px;
  line-height: 1;
  top: 8px;
  color: #888888;
  margin-left: 16px;
}

.clockPositioned {
  composes: clock;
  position: absolute;
  left: 100px;
  margin-left: 0;
}
