.banner {
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 20px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner svg {
  user-select: none;
  cursor: pointer;
  flex: 1;
  margin-left: 8px;
}
