.appbarWrapper {
  min-height: 56px;
  height: inherit;
}

.appbar {
  background-color: #3f51b5;
  position: fixed;
  height: 56px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.dialogWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 200;
  background: var(--ui-confirmation-bg, #f7f7f7);
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: var(ui-confirmation-color, #000);
  background: rgba(162, 162, 162, 0.4);
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  position: relative;
  margin: 0px 24px;
  max-width: 300px;
  width: 100%;
  /* min-height: 288px; */

  box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.25);
  background: var(--codescan-confirmation-bgcolor, #f9d953);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dialogOpen {
  display: flex;
}

.dialogClose {
  display: none;
}

.drawerWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 200;
  background: var(--ui-confirmation-bg, #f7f7f7);
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  color: var(ui-confirmation-color, #000);
  background: rgba(162, 162, 162, 0.4);
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  overflow-y: scroll;
  /* min-height: 288px; */
  background: #ffffff;
}

.drawerRight {
  right: 0px;
}

.drawerLeft {
  left: 0px;
}

.drawerOpen {
  display: flex;
}

.drawerClose {
  display: none;
}

.list {
  padding: 0px 8px;
  flex: 1;
  width: 100%;
}

.listItem {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
}

.listItemIcon {
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;
}

.listItemText {
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.listItemAvatar {
  min-width: 56px;
  flex-shrink: 0;
}

.clickable {
  user-select: none;
  cursor: pointer;
}

.fullwidth {
  width: 100%;
}

.inputFormControl {
  border: 0;
  margin: 8px;
  padding: 0;
  position: relative;
  min-width: 0;
}

.inputBase {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;

  position: relative;
  border-radius: 4px;

  /* border: 2px solid #1976cb; */
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.inputLabel {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.inputBody {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 18.5px 14px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.button {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  margin: 24px 0px 16px;
  color: #fff;
  background-color: #3f51b5;

  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.svgIcon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.largeFont {
  font-size: 2.1875rem;
}

.svgIcon > svg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.switchBase {
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 36px;
  height: 20px;
  background: #d1d1d1;
  border-radius: 10px;
}

.switchActive {
  background-color: #4caf50;
}

.switchThumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 4px;
  background: #ffffff;
}

.switchActive .switchThumb {
  left: 20px;
}

.toolbar {
  display: flex;
  align-items: center;
}

.paper {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  margin: 8px;
}

.grid {
  margin: 8px;
  padding: 8px;
}

.spacing1 {
  padding: 8px;
}

.spacing2 {
  padding: 16px;
}

.spacing3 {
  padding: 24px;
}

.gridItem {
  margin: 8px;
  padding: 8px;
}

.gridContainer {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: minmax(100px, 1fr);
}

.formControlLabel {
  display: flex;
  align-items: center;
}

.formControlLabelControl {
  margin-left: 12px;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  margin: 4px;
}

.checkboxPrimary {
  border: 1px solid #3f51b5;
}

.checkboxSecondary {
  border: 1px solid #dc004e;
}
