.body {
  background: #515151;
}

.textField {
  margin-left: 2px;
  margin-right: 2px;
  width: 200px;
}

.wrapper {
  height: 100vh;
  font-size: 18px;
  flex: 1;
}

.paymentContainer {
  display: flex;
  margin-bottom: 8px;
  /* border: 10px solid #eaf2f8; */
  position: relative;
}

.popup {
  padding: 24px;
  background-color: #fafafa;

  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
}

.pHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
}

.pSubheader {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.clickable {
  user-select: none;
  cursor: pointer;
}

.tArea {
  width: 100%;
  min-height: 365px;
  max-height: 365px;

  border: 1px solid #000000;
}

.tAreaRadioWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tAreaRadio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tAreaRadioSel {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #000000;
}

.tAreaInput {
  outline: none;
  border: 1px solid #c4c4c4;
  padding: 0px 10px;
  border-radius: 4px;
  height: 40px;
}

.tAreaRow {
  display: flex;
  align-items: center;

  margin-bottom: 8px;
  padding: 4px;
  cursor: pointer;
  user-select: none;
}

.tAreaText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  margin-left: 10px;
}

.tAreaCheck {
  border: 1px solid #000000;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tEnd {
  padding: 24px;

  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
}
.clickArea {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.hideScrollParent {
  overflow: hidden;
}

.showContent svg {
  display: flex;
}

.hideContent svg {
  display: none;
}

.allowScrollChild {
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}

.payment_id {
  margin: 10px 0;
}

.reject {
  width: 100%;
  min-height: 100%;
  background-color: green;
  color: #fff;
  position: absolute;
  z-index: 10;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0px;
  left: 0px;
}

.reject div:nth-child(2) {
  color: yellow;
  font-size: 1.5em;
}

.payment__title__wrap {
  min-width: 93px;
  min-height: 132px;
  background: black;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #96e9da;
}

.takeout {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.3em;
  margin: 10px 0;
}

.no_payment {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
}

.orderContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.orderBox {
  position: relative;
  background: white;
  height: 132px;
  width: 132px;
  margin: 0px 1px 1px 1px;
  padding: 8px;
}

.disabled {
  /* background: #9e9e9e; */
  opacity: 0.2;
}

.orderBox span {
  margin: auto;
}

.price {
  font-size: 1.25em;
  font-weight: bold;
  position: absolute;
  right: 5px;
  bottom: 5px;
  background: #16a085;
  color: #fff;
  padding: 5px;
}

.history {
  position: fixed;
  right: 0px;
  background: #000000;
  border-radius: 8px 0px 0px 8px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: 24px;
}

.historyText {
  font-family: Stratos;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: #ffffff;

  transform: rotate(-90deg);
}

.logWrapper {
  margin-top: 24px;
  background: black;
  /* flex: 3; */
  width: 128px;
  border-radius: 8px 0px 0px 8px;
  padding: 0px 16px;
}

.comment {
  font-weight: 600;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.215em;
  text-transform: uppercase;

  color: #000000;
  position: relative;

  min-height: 60px;
  background-color: #96e9da;
  padding: 0px 22px;
}

.iconHeader {
  position: absolute;
  left: 32px;
}

.center {
  flex-direction: column;
  justify-content: center;
}

.center .title {
  font-size: 22px;
  font-weight: 600;
}

.center .subtitle {
  font-size: 10px;
  font-weight: 300;
}

.tab {
  display: inline-block;
  width: 60px;
  margin-left: 8px;
  color: #000;
}

.tab:first-child {
  margin-left: 16px;
}

.tab:last-child {
  margin-right: 16px;
}

.activeTab {
  background: #f9d953 !important;
}

.iconBox {
  border: 1px solid #cdcdcd;
  border-radius: 15px;
  width: 60px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
}

.iconTitle {
  font-size: 11px;
  line-height: 18px;
  text-align: center;
}

.checkboxes {
  padding: 16px 32px;
}

.menuNavbarInput {
  background: #ffffff;
  border: none;
  outline: none;
}

.menuSearch {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 48px;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 16px;
}

.table {
  width: 100%;
  font-family: Stratos;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

.hr2 {
  margin-top: 8px;
  width: 100%;
  height: 2px;
  background-color: #96e9da;
}

.mleft8 {
  margin-left: 8px;
}

.mleft8 {
  margin-left: 8px;
}

.mleft32 {
  margin-left: 32px;
}

.mleft48 {
  margin-left: 48px;
}

.mleft80 {
  margin-left: 80px;
}

.mtop8 {
  margin-top: 8px;
}

.mtop16 {
  margin-top: 16px;
}

.mtop32 {
  margin-top: 32px;
}

.flex1 {
  flex: 1;
}

.flex {
  display: flex;
}

.fCenter {
  justify-content: center;
  align-items: center;
}

.fColumn {
  flex-direction: column;
}

.jcsb,
.jcBetween {
  justify-content: space-between;
}

.tableFont {
  font-size: 24px;
  line-height: 24px;
}

.tableFont2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 28px;
}

.orderTitle {
  z-index: 3;
  position: relative;
  word-break: break-word;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;

  color: #000000;
}

.optionsTitle {
  z-index: 3;
  position: absolute;
  bottom: 8px;

  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.active {
  background-color: #6fe465;
}

.active .orderTitle {
  color: white;
}

.active .optionsTitle {
  color: white;
}

.takeoutIcon {
  position: absolute;
  top: 63px;
  left: 20px;
  z-index: 1;
}

.themeSquare2 {
  background: rgb(255, 105, 0);
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 4px;
}

.themeSquare {
  background: rgb(255, 105, 0);
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative;
  outline: none;
  float: left;
  border-radius: 4px;
  margin: 0px 6px 6px 0px;
}

.picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.default {
  background-color: #6fcf97;
}
.fall {
  background-color: #f2c94c;
}
.fallnig {
  background-color: #f2994a;
}
.forest {
  background-color: #27ae60;
}
.deepfor {
  background-color: #219653;
}
.sky {
  background-color: #2d9cdb;
}
.water {
  background-color: #2f80ed;
}
.snow {
  background-color: #56ccf2;
}
.pinky {
  background-color: #bb6bd9;
}
.fluff {
  background-color: #eb5757;
}
.magician {
  background-color: #9b51e0;
}

.tutsi {
  background-color: #333333;
}

.logWrapper {
  background: #494949;
  /* flex: 3; */
  width: 264px;
  position: relative;
  overflow: hidden;
}
