.card {
  padding: 0px 8px;
  max-width: 345px;
  min-width: 345px;
  margin: 2px 8px;
  min-height: 56px;
  padding: 4px 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: black;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.media {
  height: 24;
  width: 24;
}
.font {
  font-size: 18px;
  line-height: 24px;
  margin-left: 8px;
}
.fontSmall {
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: rgba(255, 255, 255, 0.5);
}
.fontSmall2 {
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.5);
}
.stoplisted {
  background-color: #000000;
  color: white;
}
.flexD {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
  align-items: center;
}
.title {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
