.wrapper {
  min-height: 100vh;
  font-size: 24px;
  background-color: #323232;
}

.flex {
  display: flex;
}

.main {
  flex: 7;
  padding: 4px;
}

.payment {
  width: 100%;
  min-height: 76px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  margin-bottom: 4px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
}

.vr {
  width: 1px;
  min-height: 76px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}

.paymentCard {
  flex: 24;
  /* width: 168px; */
  max-width: 168px;
  min-width: 168px;
  min-height: 76px;
  display: flex;
}

.blockRow {
  /* flex: 79; */
  max-width: 100%;
  /* width: 100%; */
  flex-wrap: wrap;
  display: flex;
}

.blockCard {
  min-width: 193px;
  max-width: 193px;
  min-height: 76px;
  margin-left: 2px;
  position: relative;
}

.whiteCard {
  background-color: #ffffff;
  color: #000000;
}

.minicross {
  stroke: #ffffff;
}

.whiteCard .minicross {
  stroke: #ff785a;
}

.whiteCard .nameCount {
  color: #ff785a;
}

.nameCount {
  display: flex;
  align-items: center;

  font-weight: bold;
  font-size: 19px;
  line-height: 18px;
  margin-bottom: 4px;
}

.minicross {
  /* font-size: 10px; */
}

.redCard {
  background-color: #ff0000;
  color: #ffffff;
}

.blueCard {
  background-color: #32b3c4;
  color: #ffffff;
}

.yellowCard {
  background-color: #f8b200;
  color: #ffffff;
}

.greenCard {
  background-color: #7ad089;
  color: #ffffff;
}

.blackCard {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.7);
}

.notBlackCard {
  background-color: #3c3c3c;
  color: #ffffff;
}

.notBlackCard2 {
  background-color: #3c3c3c;
  color: rgba(255, 255, 255, 0.7);
}

.greenDish {
  color: #7ad089;
}

.timerWrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
}

.timerText1 {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 18px;
  text-align: center;
}

.timerText2 {
  margin-top: 12px;
  text-transform: uppercase;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.blockCardContent {
  padding: 8px;
}

.mtop1 {
  margin-top: 1px;
}

.firstBlock {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 5;
}

.secondBlock {
  width: 40px;
  display: flex;
  align-items: center;
  flex: 5;
}

.paymentInfoWrapper {
  position: relative;
  flex: 16;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paymentInfo {
  flex: 1;
  padding: 10px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.time {
  text-align: right;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
}

.takeoutText {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.name {
  text-transform: uppercase;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 18px;
}

.subname {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 14.7462px;
  line-height: 18px;
}

.comment {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.notViableOrders {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.paymentTakeoutRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loaderWrapper {
  position: relative;
  height: calc(100vh - 20px);
}

.loaderWrapper::before {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top: 2px solid grey;

  animation: load 0.7s linear infinite;
}

@keyframes load {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
